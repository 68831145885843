import * as React from "react";
import { Link } from "gatsby";

import BtnPlusBorderWhite from "../btnPlusBorderWhite";

const ProjectsDisplayMobile = ({ projects }) => {
    return (
        <div className="main-container-projects ">
            {projects?.map((item, index) => (
                <div key={'project' + index} className="container-project position-relative">
                    <Link to={item.projetos.url} className="position-relative">
                        <img src={item.featuredImage.node.sourceUrl} alt={item.projetos.name} className="img-project" />
                        <div className="position-relative pb-0 pt-0 container-btn-plus container-margin">
                            <BtnPlusBorderWhite />
                        </div>
                        <div className=" pt-3 pb-0 container-mobile-text container-margin">
                            <div className="d-grid w-100 container-project-name-button">
                                <p className="black fs-5 IMedium uppercase mb-0 first-col">{item.projetos.name}</p>
                            </div>
                            <p className="fs-6 black IRegular mt-2">{item.projetos.shortDescription}</p>
                        </div>
                    </Link>
                </div>
            ))}

        </div >
    );
};

export default ProjectsDisplayMobile;