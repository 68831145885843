import * as React from "react";
import { Link } from "gatsby";
import BtnPlusBorderWhite from "../btnPlusBorderWhite";

const ProjectsDisplay = ({ projects }) => {
    return (
        <div className="main-container-projects">
            {projects.length > 0 &&
                projects.map((item, index) => (
                    <div key={'project' + index} className="container-project position-relative">
                        <Link to={item.projetos.url} className="container-image-icon-plus">
                            <BtnPlusBorderWhite />
                            <img src={item.featuredImage.node.sourceUrl} alt={item.projetos.name} className="img-project" />
                        </Link>
                        <div className="container-margin  mt-4 mb-2 pt-0 pb-0">
                            <h3 className="fs-3 IMedium black">{item.projetos.name} </h3>
                            <p className="fs-6 black IRegular mt-2">{item.projetos.shortDescription}</p>
                        </div>
                    </div>
                ))}

        </div >
    );
};

export default ProjectsDisplay;